import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Blockquote from '@components/molecules/Blockquote';
import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
// import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#17A345';
const footerRelatedLinks = [
	{ title: 'Ackermann', url: '/fr/projets/ackermann-branding/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/hesav/hesav_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: { eq: "projects/hesav/hesav_hero_mobile.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/hesav/hesav_3.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/hesav/hesav_4.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/hesav/hesav_5.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/hesav/hesav_6.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1500, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/hesav/hesav_7.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1500, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/hesav-haute-ecole-vaudoise-sante/',
					lang: 'en',
				},
			]}
			title="HESAV - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Haute école de Santé vaudoise"
				// replace with this videoCode e9711ce590c51e1fc6a807beb8a7a56c
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Développer les meilleurs traitements en se
							focalisant sur les besoins réels des patients.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Stratégie de design',
								'Recherche utilisateur',
								'Stratégie digitale',
								'Design digital',
								'Développement web',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							Une dispersion de l’information à travers une
							multitude de sites web, des audiences très diverses
							avec des besoins différents et un ensemble riche
							d'entités (académique, recherche, formation) rendant
							complexe la mise en place d'une solution efficace.
						</p>
						<h3>Notre réponse</h3>
						<p>
							La fondation de notre projet s'est basée sur de la
							recherche utilisateur avec la volonté de maintenir
							les étudiants comme les chercheurs au cœur du
							processus. Interviews, étude d'observation et
							analyse de données nous ont permis de construire une
							réponse efficace et basée sur des faits. Cette
							démarche nous a permis de rassembler la majorité des
							informations sur une unique plateforme web par
							l’intermédiaire de parcours utilisateurs adaptés à
							chaque typologie.
						</p>
						<div className="project-links text-content">
							<ButtonExternal
								href="https://www.hesav.ch/"
								text="Voir le site"
							/>
						</div>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div>
						<Image
							{...data.image_3.childImageSharp}
							alt="Processus HESAV en 3 étapes"
						/>
					</div>
				</div>

				<Video
					className="col-full"
					src="f0f34d351aa5a5ebfc78f071958f0002"
					alt="Animation Homepage"
				/>

				{/***** INTRODUCTION CONTEXTES *****/}
				<div className="box text-content" data-animation-page>
					<div className="list-two-columns">
						<div className="text-content">
							<h3>
								Des besoins différents, des expériences
								différenciées
							</h3>
						</div>
						<div className="text-content">
							<p>
								Selon qu'on soit intéressé à s'inscrire, un
								étudiant, un chercheur ou un employé de l'école,
								nous avons simplifié la structure des menus
								permettant un accès efficace à l'information
								recherchée.
							</p>
						</div>
					</div>
				</div>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.image_6.childImageSharp}
						alt="Mobile Design"
					/>
					<Image
						{...data.image_7.childImageSharp}
						alt="Mobile Design"
					/>
				</Gallery>

				<Video
					className="col-full"
					src="3260f5aeec42665d11e9c6203b107e43"
					alt="Animation Menu"
				/>

				{/***** VALORISATION TYPO *****/}
				<div className="box text-content" data-animation-page>
					<h3>Evolution typographique</h3>
					<div className="list-two-columns align-items-center">
						<Image
							{...data.image_4.childImageSharp}
							alt="Typography"
						/>
						<Image
							{...data.image_5.childImageSharp}
							alt="Typography"
						/>
					</div>
					<div className="list-two-columns">
						<div className="text-content">
							<p>
								Notre travail s'est aussi porté sur la marque
								HESAV en proposant une adaptation du logo et la
								modernisation des codes graphiques avec
								l’utilisation d'une typographie au caractère
								singulier.
							</p>
						</div>
						<div className="text-content"></div>
					</div>
				</div>

				<Video
					className="col-full"
					src="ffbc7e5f2c4458c114c374b186a88e80"
				/>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
